import Image from "next/image";

import { Props } from "./types";

const Card = ({ title, subtitle, size = "big", image, titleColor, subtitleColor }: Props): JSX.Element => {
  return (
    <div
      className={`flex flex-row md:flex-col items-center ${
        size === "big" ? "md:w-60 xl:w-96" : "md:w-48 xl:w-60"
      }`}
    >
      <div
        className={`relative w-16 h-20 mr-5 min-w-[4rem] ${
          size === "big"
            ? "w-14 md:w-36 md:h-28 xl:w-44 xl:h-36"
            : "md:w-16 md:h-20 xl:w-28 xl:h-32"
        }`}
      >
        <Image src={image} alt={title} layout="fill" objectFit="contain" />
      </div>

      <div
        className={`flex flex-col justify-start text-left md:text-center md:items-center md:justify-center`}
      >
        <h3
          className={`${
            size === "big" ? "whitespace-nowrap" : ""
          } leading-10 font-sans font-bold ${
            size === "big"
              ? "md:text-2xl md:font-normal md:mb-5 xl:text-3xl xl:font-bold xl:mb-3 md:mt-6"
              : "md:font-sans md:font-bold md:text-md md:mb-4 xl:text-xl xl:mb-4 md:mt-3"
          } ${ titleColor || "text-landing-blue" }`}
        >
          {title}
        </h3>

        <p className={`leading-6 text-left md:w-auto md:text-center md:font-sans md:font-normal md:text-base ${subtitleColor || "text-landing-blue" }`}>
          {subtitle}
        </p>
      </div>
    </div>
  );
};

export default Card;
