import { useEffect, useState, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useSwipeable } from "react-swipeable";

import CarouselItem from "./components/CarouselItem";
import { useWindowSize } from "@/shared/hooks/useWindowSize";
import Screen from "@/constants/screens";
import Chevron from "public/icons/chevron-thick-right.svg";

import { Props } from "./types";
import { generateCarouselData } from "./utils";

const HowItWorksCarousel = ({ theme = "dark", carouselType = "landing" }: Props): JSX.Element => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [hoveredIndex, setHoveredIndex] = useState<null | number>();
  const [carouselActive, setCarouselActive] = useState<boolean>(false);
  const handlers = useSwipeable({
    onSwipedLeft: () => onNextButtonClick(),
    onSwipedRight: () => onPreviousButtonClick(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  const myRef = useRef<HTMLInputElement>();
  const refPassthrough = (el: any) => {
    handlers.ref(el);
    myRef.current = el;
  };
  
  const howItWorksDivPosition = myRef?.current?.getBoundingClientRect();

  const { width, height } = useWindowSize();
  const carouselData = generateCarouselData(carouselType) || [];

  const onTitleClick = (selectedTitleIndex: number) => {
    setActiveIndex(selectedTitleIndex);
  };

  const onTitleHover = (selectedTitleIndex: number) => {
    setHoveredIndex(selectedTitleIndex);
    setCarouselActive(false);
  };

  const onTitleLeave = () => {
    setHoveredIndex(null);
    setCarouselActive(true);
  };

  const onPreviousButtonClick = () => {
    setActiveIndex((currentIndex) => {
      const updatedIndex = (currentIndex - 1) % carouselData.length;
      return updatedIndex === -1 ? carouselData.length - 1 : updatedIndex;
    });
  };

  const onNextButtonClick = () => {
    setActiveIndex((currentIndex) => (currentIndex + 1) % carouselData.length);
  };

  useEffect(() => {
    const interval = setInterval(
      () => setActiveIndex((currentIndex) => (currentIndex + 1) % carouselData.length),
      5000,
    );

    if (!carouselActive) {
      clearTimeout(interval);
    }
    return () => clearTimeout(interval);
  }, [carouselActive, activeIndex, carouselData.length, myRef, height]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        howItWorksDivPosition &&
        howItWorksDivPosition.top - howItWorksDivPosition.height > window.scrollY
      ) {
        setCarouselActive(false);
      } else {
        setCarouselActive(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [howItWorksDivPosition]);

  return (
    <section
      className={`block relative h-[57rem] md:h-[41.25rem] xl:h-auto ${
        theme === "dark" ? "bg-white" : "bg-landing-ivory xl:pt-12 xl:mb-36"
      }`}
    >
      <div id="howitworks" className="absolute top-4 md:top-0 xl:top-16" />

      <div
        className={`flex flex-col md:items-center xl:flex-row xl:space-x-20 relative  ${
          carouselType === "landing"
            ? "-top-36 md:-top-16 xl:-top-32 xl:h-[66.5rem]"
            : "top-0 xl:h-[61rem]"
        }  z-0 max-h-[72.5rem]`}
      >
        <div
          className={`flex flex-col items-center justify-center ${
            theme === "dark" ? "bg-landing-blue" : "bg-landing-denim"
          } 
          ${
            carouselType === "landing"
              ? "pt-52 md:pt-28 xl:py-64 xl:pb-28"
              : "pt-10 md:pt-10 md:pb-6 xl:pt-20 xl:pb-16"
          } h-full pb-6 xl:pl-32 w-full xl:w-1/2 xl:rounded-tr-[4.75rem] xl:rounded-br-[4.75rem] xl:items-start`}
        >
          <h2 className="mb-6 text-4xl font-bold text-white xl:text-5xl md:mb-8 xl:mb-3">
            How it works
          </h2>

          <div className="relative flex justify-around w-full md:justify-around xl:flex-col xl:max-w-max md:w-96 xl:w-[30.25rem]">
            <div className="absolute z-20 w-screen h-px mx-auto bg-white absolute-center-xy xl:hidden"></div>

            {carouselData.map(({ header }, idx) => (
              <CarouselItem
                key={idx}
                title={header}
                index={idx}
                onClick={onTitleClick}
                onHover={onTitleHover}
                onLeave={onTitleLeave}
                isActive={activeIndex === idx || hoveredIndex === idx}
                variant={width < Screen.DESKTOP_1280 ? "mobile" : "desktop"}
                isLastItem={idx === carouselData.length - 1}
                theme={theme}
              />
            ))}
          </div>
        </div>

        <div
          className={`relative flex flex-col w-full md:flex-row xl:flex-col md:items-center h-[45rem] md:h-auto md:pl-6 lg:pl-11 xl:pl-0 overflow-hidden  xl:h-full ${
            theme === "dark" ? "xl:pt-32" : "xl:pt-0"
          }  xl:w-1/2 pt-9 md:pt-16 lg:pt-20`}
          {...handlers}
          ref={refPassthrough}
        >
          <div
            className="relative w-full mx-auto xl:max-w-none xl:top-24"
            onMouseEnter={() => width >= Screen.DESKTOP_1280 && setCarouselActive(false)}
            onMouseLeave={() => setCarouselActive(true)}
          >
            <Carousel
              showIndicators={false}
              showStatus={false}
              showArrows={false}
              showThumbs={false}
              swipeable={false}
              axis="horizontal"
              animationHandler={width <= Screen.DESKTOP_1280 ? "slide" : "fade"}
              selectedItem={hoveredIndex ?? activeIndex}
              renderItem={(item) => (
                <div className="relative z-50 flex flex-col items-center mx-auto md:flex-row xl:flex-col md:max-w-5xl xl:max-w-none">
                  {item}
                  <div className="relative z-40 flex flex-col items-center justify-center w-full px-5 mb-9 md:mb-0 md:h-80 xl:h-auto md:px-6 lg:px-11 xl:px-0">
                    <div className="text-center h-[23rem] md:h-[24rem] xl:h-[18rem] relative md:flex md:justify-center md:flex-col md:items-center xl:block max-w-sm md:max-w-none">
                      <h3 className="inline-block relative max-w-[14.5rem] mt-6 font-serif text-2xl font-normal text-center md:w-[28rem]  md:max-w-xs xl:max-w-none md:text-3xl xl:font-bold text-landing-blue xl:w-[32.75rem]">
                        {carouselData[hoveredIndex ?? activeIndex].header}
                        <Chevron
                          className="absolute z-50 w-3 rotate-180 -left-9 md:hidden xs:-left-14 absolute-center-y"
                          onClick={onPreviousButtonClick}
                        />
                        <Chevron
                          className="absolute z-50 w-3 -right-9 md:hidden xs:-right-14 absolute-center-y"
                          onClick={onNextButtonClick}
                        />
                      </h3>

                      <p className="relative max-w-lg z-40 my-6 font-sans text-base leading-6 text-center md:max-w-xs xl:max-w-none  text-landing-blue xl:w-[34.25rem]">
                        {carouselData[hoveredIndex ?? activeIndex].text}
                      </p>

                      <Link href={carouselData[hoveredIndex ?? activeIndex].link.href}>
                        <a className="relative min-w-[8.5rem] z-40 inline-block w-full px-4 py-3 text-base font-bold text-center text-white transition duration-300 ease-in-out transform rounded shadow md:w-fit bg-landing-orange hover:scale-105">
                          {carouselData[hoveredIndex ?? activeIndex].link.caption}
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            >
              {carouselData.map(({ image, title }, idx) => (
                <div
                  key={idx}
                  className={`relative w-[21.5rem] h-[18.25rem] xs:w-[26.5rem] xs:h-[18.5rem] md:w-[52rem] md:h-[26rem] xl:w-[38rem] xl:h-[27rem] ${
                    idx === 0 && "p-10"
                  }`}
                >
                  <Image src={image} alt={title} objectFit="contain" layout="fill" />
                </div>
              ))}
            </Carousel>
            <div className="absolute items-end justify-between hidden w-full bottom-8 xl:bottom-0 md:flex md:justify-end xl:justify-between xl:relative">
              <div
                className={`relative hidden w-40 h-10 xl:inline-block ${
                  carouselType === "landing" ? "xl:inline-block" : "xl:hidden"
                }`}
              >
                <Image
                  src="/landing/howItWorksCarousel/doodle-3.svg"
                  alt="doodle"
                  layout="fill"
                  objectFit="contain"
                />
              </div>

              <div className="right-0 hidden h-10 md:relative md:inline-block md:w-28 md:h-16 xl:right-5 xl:w-32 -z-1">
                <Image
                  src="/landing/howItWorksCarousel/semi-circle.svg"
                  alt="Semi circle"
                  layout="fill"
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksCarousel;
