import CarouselItemDesktop from "./CarouselItemDesktop";
import CarouselItemMobile from "./CarouselItemMobile";
import { Props } from "./types";

const CarouselItem = ({
  variant = "desktop",
  ...props
}: Props & { variant: "mobile" | "desktop" }): JSX.Element =>
  variant === "desktop" ? <CarouselItemDesktop {...props} /> : <CarouselItemMobile {...props} />;

export default CarouselItem;
