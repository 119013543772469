import { Props } from "./types";

const CarouselItemMobile = ({ isActive, onClick, theme, index }: Props): JSX.Element => {
  return (
    <button
      className="relative flex items-center justify-start transition duration-300 ease-in-out rounded-2xl"
      onClick={() => onClick(index)}
    >
      <div
        className={`relative flex items-center justify-center w-[3.125rem] h-[3.125rem] border rounded-full transition-all duration-300 ease-in-out 
        ${isActive && theme === "dark" && "border-white"}
        ${!isActive && theme === "dark" && "border-landing-blue z-40 bg-landing-blue"}
        
        ${isActive && theme === "light" && "border-white "}
        ${!isActive && theme === "light" && "border-landing-denim z-40 bg-landing-denim"}
       `}
      >
        <div
          className={`relative rounded-full flex justify-center items-center max-w-md transition-all duration-300 ease-in-out ${
            isActive
              ? " bg-white min-w-[39px] h-[2.438rem] w-[2.438rem]"
              : "bg-landing-orange min-w-[1.75rem] w-7 h-7 "
          }`}
        >
          <span
            className={`transition-all duration-300 ease relative z-50 p-3 font-bold 
            ${isActive ? "text-2xl" : "text-lg"}
            ${isActive && theme === "light" ? "text-landing-blue" : ""}
            ${!isActive && theme === "light" && "text-white"}

            `}
          >
            {index + 1}
          </span>
        </div>

        {/* triangle indicator */}
        <div
          className={`overflow-hidden w-[2.5rem] absolute-center-x -bottom-7 transition-all duration-300 ease-in-out ${
            isActive ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="origin-bottom-left transform rotate-45 bg-white w-7 h-7"></div>
        </div>
      </div>
    </button>
  );
};

export default CarouselItemMobile;
