import { CarouselData } from "./types";

import BudgetInPrinciple from "@/public/landing/howItWorksCarousel/budget-in-principle.svg?url";
import FindYourHome from "@/public/landing/howItWorksCarousel/find-your-home.svg?url";
import SubmitApplication from "@/public/landing/howItWorksCarousel/submit-application.svg?url";
import MoveIn from "@/public/landing/howItWorksCarousel/move-in.svg?url";
import BuyHome from "@/public/landing/howItWorksCarousel/buy-home.svg?url";

import NinetySeconds from "@/public/landing/howItWorksCarousel/90seconds.svg?url";
import BuyWhenReady from "@/public/landing/howItWorksCarousel/buy-when-ready.svg?url";
import routes from "@/constants/routes";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const carouselLandingData: CarouselData = [
  {
    title: "Find out your estimated budget",
    header: "Find out your estimated budget",
    text: "Answer a few simple questions to get an estimated budget within minutes (no impact on your credit score).",
    image: BudgetInPrinciple,
    link: {
      caption: "Calculate your budget",
      href: routes.budgetCalculator,
    },
  },
  {
    title: "Find your dream home",
    header: "Find your new home",
    text: "Find the home (for you and your family) and we will negotiate a price that makes sense for you.",
    image: FindYourHome,
    link: {
      caption: "Get your budget",
      href: routes.application.root,
    },
  },
  {
    title: "Submit a full application and Keyzy will buy your home",
    header: "Apply for Keyzy to buy your home",
    text: "We will perform a thorough check on you and the property. Once approved, we will buy the home on your behalf. We take care of the boring stuff (e.g., surveys, conveyancing costs and buildings insurance). You can just sit back and relax.",
    image: SubmitApplication,
    link: {
      caption: "Sign up now",
      href: routes.application.root,
    },
  },
  {
    title: "Move in and start saving",
    header: "Move in and start saving",
    text: "Move into your new home. Your monthly payments are fixed (no surprises!). Up to 25% of these payments are converted into reducing the purchase price when you buy the property from Keyzy later on.",
    image: MoveIn,
    link: {
      caption: "Calculate your budget",
      href: routes.budgetCalculator,
    },
  },
  {
    title: "Buy the home when you’re ready, or walk away with the upside",
    header: "Buy the home when you’re ready, or walk away with the upside",
    text: "You can decide to buy the property from us at any time at the original purchase price (plus costs). If you decide to leave instead you can find another buyer and walk away with your converted rent and any increase in property value.",
    image: BuyHome,
    link: {
      caption: "Find out more",
      href: routes.budgetCalculator,
      // caption: "FAQs",
      // href: routes.faqs,
    },
  },
];

const intermediariesCarouselData: CarouselData = [
  {
    title: "90 seconds to get an estimated budget for your clients",
    header: "90 seconds to get an estimated budget for your clients",
    text: "Get a budget in principle in moments, without impacting your clients’ credit score.",
    image: NinetySeconds,
    link: {
      caption: "Join the network",
      href: `${publicRuntimeConfig?.BROKER_PORTAL_URL}/sign-up`,
    },
  },
  {
    title: "Clients find their dream home",
    header: "Clients find their dream home",
    text: "Give your clients the comfort that they can find the home of their dreams, and we will negotiate a price that makes sense for them.",
    image: FindYourHome,
    link: {
      caption: "Join the network",
      href: `${publicRuntimeConfig?.BROKER_PORTAL_URL}/sign-up`,
    },
  },
  {
    title: "Submit a full application and Keyzy will buy the property",
    header: "Apply for Keyzy to buy your clients’ home",
    text: "We will perform a thorough check on your client and the property. Once approved, we will buy the home on their behalf and take care of the boring stuff (e.g., surveys, conveyancing costs and buildings insurance). Your client can just sit back and relax.",
    image: SubmitApplication,
    link: {
      caption: "Join the network",
      href: `${publicRuntimeConfig?.BROKER_PORTAL_URL}/sign-up`,
    },
  },
  {
    title: "Move in and start saving",
    header: "Your clients move in and start saving",
    text: "Your clients move into their new home. Monthly payments are fixed (no surprises!) for the duration of the lease. Up to 25% of these payments are deducted from the purchase price when they buy the property from Keyzy later on.",
    image: MoveIn,
    link: {
      caption: "Join the network",
      href: `${publicRuntimeConfig?.BROKER_PORTAL_URL}/sign-up`,
    },
  },
  {
    title: "Buy the home when ready, or walk away with the upside",
    header: "Your clients can buy the home when ready, or walk away with the upside",
    text: "At any point in time, your clients can decide to buy the property from Keyzy at the original purchase price (plus costs). If they decide to leave, they can find a buyer and walk away with the converted rent and any increase in value.",
    image: BuyWhenReady,
    link: {
      caption: "Join the network",
      href: `${publicRuntimeConfig?.BROKER_PORTAL_URL}/sign-up`,
    },
  },
];

export const generateCarouselData = (type = "landing" || "intermediaries") => {
  switch (type) {
    case "landing":
      return carouselLandingData;
    case "intermediaries":
      return intermediariesCarouselData;
  }
};
