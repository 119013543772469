import Image from "next/image";
import { useRef } from "react";

import { Props } from "./types";

const ExpandableBox = ({ question, answer, active, clickHandler, page }: Props) => {
  const contentSpace = useRef<HTMLDivElement>(null);
  const isHiwPage = page === "hiw";
  const isLandlordsPage = page === "landlords";
  const intermediariesPage = page === "intermediaries";
  const isReferralPage = page === "referral";

  return (
    <div
      className={`mb-4 text-landing-blue rounded-2xl ${
        isLandlordsPage || intermediariesPage
          ? "bg-[#FFE9D3]"
          : isReferralPage
          ? "bg-white"
          : "bg-landing-gray"
      } `}
    >
      <div
        className={`flex items-center justify-between ${
          isHiwPage || isLandlordsPage || isReferralPage ? "h-24 md:h-[58px]" : "h-24"
        } cursor-pointer`}
        onClick={clickHandler}
      >
        <div
          className={`ml-6 mr-1 w-10/12 ${active && "font-bold"} ${
            active && isReferralPage && "text-[#00acc1]"
          }`}
        >
          {question}
        </div>
        <div
          className={`flex justify-end mr-6 w-2/12
          }`}
        >
          <Image
            className={`transform duration-700 ease ${active && "rotate-90"}`}
            src="/icons/chevron-thick-right.svg"
            width={`17`}
            height={`17`}
            alt="chevron"
          />
        </div>
      </div>

      <div
        ref={contentSpace}
        style={{
          maxHeight: active ? `${contentSpace?.current?.scrollHeight}px` : "0px",
        }}
        className={`overflow-hidden text-sm transition-all duration-700 ease-in-out mx-6`}
      >
        <div className="flex">
          <div dangerouslySetInnerHTML={{ __html: answer }} className="w-10/12" />
          <div className="w-2/12"></div>
        </div>

        <div className="pb-8" />
      </div>
    </div>
  );
};

export default ExpandableBox;
