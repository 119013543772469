import { Props } from "./types";

const CarouselItemDesktop = ({
  isActive,
  onClick,
  onLeave,
  onHover,
  index,
  isLastItem,
  title,
  theme,
}: Props): JSX.Element => {
  return (
    <button
      className={`relative w-full -left-8 flex justify-start items-center after:content-[''] after:absolute after:-bottom-10 after:h-10 z-50 after:w-full after:cursor-auto  px-8 py-6 my-5 cursor-pointer rounded-2xl border hover:border-white hover:border transition ease-in-out duration-300  ${
        isActive && "border-white"
      }
      ${!isActive && theme === "dark" && "border-landing-blue"}
      
      ${!isActive && theme === "light" && "border-landing-denim z-40 bg-landing-denim"}
         `}
      onClick={() => onClick(index)}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={onLeave}
    >
      <div
        className={`relative rounded-full mr-7 flex justify-center items-center max-w-md transition-all duration-300 ease-in-out bg-landing-orange min-w-[2.563rem] w-10 h-10`}
      >
        <span
          className={`z-50 text-2xl font-bold ${
            theme === "dark" ? "text-landing-blue" : "text-white"
          }`}
        >
          {index + 1}
        </span>

        <div
          className={`absolute-center-x -bottom-[4.75rem] h-[3.75rem] w-px z-10 bg-white ${
            isLastItem && "hidden"
          } `}
        />
      </div>

      <h3 className="font-serif text-xl leading-6 text-left text-white">{title}</h3>
    </button>
  );
};

export default CarouselItemDesktop;
